import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { DefineComponent, createApp, h } from 'vue';
import { ZiggyVue } from 'ziggy';
import { Head, Link, createInertiaApp, router } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import Card from '@/Components/Card.vue';
import Icon from '@/Components/Icon.vue';
import '../css/app.css';
import AppLayout from './Layouts/AppLayout.vue';
import { navigation } from './stores';

const appName = import.meta.env.VITE_APP_NAME || 'GRID';

router.on('before', (event) => {
    if (navigation.autoSaveFieldProcessing) {
        navigation.pendingVisit = event.detail.visit;
        return false;
    }
});

router.on('navigate', (event) => {
    if (event.detail.page.props.auth.user) {
        Sentry.setUser({
            id: event.detail.page.props.auth.user.id,
            email: event.detail.page.props.auth.user.email,
        });
    } else {
        Sentry.setUser(null);
    }
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        let page = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
        );
        if (
            !(
                name.startsWith('Auth') ||
                name.startsWith('Profile') ||
                ['PrivacyPolicy', 'TermsOfService'].includes(name)
            )
        ) {
            page.default.layout = page.default.layout || AppLayout;
        }
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                release: __RELEASE__,
                environment: import.meta.env.VITE_APP_ENV,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.httpClientIntegration({
                        failedRequestStatusCodes: [419, [500, 599]],
                    }),
                ],
                trackComponents: true,
                tracesSampleRate: 0.8,
                tracePropagationTargets: ['localhost', /^https:\/\/grid\.test/, /^https:\/\/grid\.mendotagroup\.com/],
            });
        }
        app.use(plugin)
            .use(ZiggyVue)
            .component('Card', Card)
            .component('Head', Head)
            .component('Icon', Icon)
            .component('Link', Link)
            .mount(el);
    },
    progress: {
        includeCSS: false,
    },
});
